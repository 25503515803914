
































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  dialog = false
  resolve?: (value: any) => void

  blind(val: boolean) {
    this.resolve && this.resolve(val)
    this.close()
  }

  open() {
    this.dialog = true
    return new Promise((resolve) => (this.resolve = resolve))
  }

  close() {
    this.dialog = false
  }
}
